import React, { useRef, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import theme from "../../../../assets/theme";
import COLORS from "../../../../assets/Colors";
import styled from "styled-components";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 10,
    marginRight: 10,
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiPaginationItem-root": {
      margin: 0,
      fontFamily: theme.fonts.primaryFontRegular,
      color: COLORS.COLOR_DARK,
      fontSize: 14,
      height: 36,
      width: 36,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .MuiPaginationItem-outlined": {
      border: 0,
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#efefef",
      border: "solid 0.1px #bbbbbb;",
      borderRadius: 0,
      fontFamily: theme.fonts.primaryFontBold,
    },
    "& .MuiPagination-ul": {
      border: "solid 0.5px #bbbbbb;",
      borderRadius: 4,
    },
    "& li": {
      borderRight: "solid 0.5px #bbbbbb",
    },
    "& li:last-child": {
      borderRight: 0,
    },
  },
}));

export default function CustomPagination({
  MTRef,
  state,
  count,
  onChangePage,
  onChangeRowsPerPage,
  colSpan,
  ...props
}) {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const PaginationRef = useRef();
  const themes = useTheme();
  const isBigScreen = useMediaQuery(themes.breakpoints.up(640));

  function handlePageChangeLast(page) {
    onChangePage(0, page - 1);
    setPage(Math.ceil(props.props.count / 10));
  }

  function handlePageChangeFirst(page) {
    onChangePage(0, page - 1);
    setPage(page);
  }

  return count > 0 && props.props.count > 10 ? (
    <div className={classes.root}>
      <NextLast onClick={() => handlePageChangeFirst(1)} margin={"right"}>
        First
      </NextLast>
      <Pagination
        ref={PaginationRef}
        count={count}
        onChange={(e, page) => {
          onChangePage(e, page - 1);
          setPage(page);
        }}
        variant="outlined"
        shape="rounded"
        page={page}
        siblingCount={isBigScreen ? 1 : 0}
        boundaryCount={1}
      />
      <NextLast onClick={() => handlePageChangeLast(colSpan)} margin={"left"}>
        Last
      </NextLast>
    </div>
  ) : (
    <div></div>
  );
}

const NextLast = styled.div`
  width: 55px;
  height: 36.5px;
  border-radius: 4px;
  border: solid 0.5px #bbbbbb;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  ${({ margin }) => `margin-${margin}`}: 5px;
  user-select: none;
  cursor: pointer;
`;
