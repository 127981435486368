import React from "react";
import ActionBar from "../../common/ActionBar";
import { Container } from "../CreateParty/styles";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { Formik } from "formik";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import Rename from "./component/Rename";
import _ from "lodash";
import { navigate } from "@reach/router";

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: COLORS.PRIMARY_WHITE,
    boxShadow: "none",
    width: "77px",
    height: "30px",
    color: COLORS.BTN_GREEN,
    textTransform: "Capitalize",
    border: "solid 1px #00838c",
    fontFamily: theme.fonts.primaryFontBold,
    fontSize: 14,
  },
  formLabel: {
    fontFamily: theme.fonts.primaryFontSemiBold,
    color: COLORS.COLOR_DARK,
  },
  table: {
    backgroundColor: "transparent",
    border: "1px solid #e1e3ee",
    borderRadius: 7,
    "& .MuiTableCell-root": {
      borderBottom: 0,
    },
  },
  tableHead: {
    fontFamily: "openSans-SemiBold, sans-serif",
    color: COLORS.INPUT_LABEL,
    fontSize: 12,
  },
  tableBody: {
    fontFamily: "openSans-SemiBold, sans-serif",
    color: COLORS.COLOR_DARK,
    fontSize: 12,
  },
}));

const ManagementTools = () => {
  const classes = useStyles();
  const inputs = [
    {
      label: "Rename",
      name: "rename",
      value: "rename",
    },
  ];

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "dashboard":
        navigate("/dashboard");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <ActionBar
        breadcrumbs={["Dashboard", "Management tools"]}
        {...{ onBreadcrumbClick }}
      />
      <Container>
        <Formik
          initialValues={{
            type: "rename",
          }}
          enableReinitialize
          validateOnBlur
          validateOnChange
          onSubmit={""}
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            setFieldValue,
            setFieldError,
            handleBlur,
            isValid,
          }) => (
            <Grid item xs={12} spacing={2}>
              <RadioGroup
                className={classes.radioGroup}
                style={{
                  flexDirection: "row",
                  marginLeft: "15px",
                }}
                name={"type"}
                value={values.type}
                onChange={handleChange}
              >
                {inputs.map((radio, key) => (
                  <FormControlLabel
                    {...{ key }}
                    classes={{ label: classes.formLabel }}
                    value={radio.value}
                    // checked={values.ownerType === radio.name}
                    control={
                      <Radio size="medium" style={{ color: "#00838c" }} />
                    }
                    label={radio.label}
                  />
                ))}
              </RadioGroup>
              {values?.type === "rename" ? <Rename /> : null}
            </Grid>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default ManagementTools;
