import React from "react";
import labels from "../../../helpers/labels.json";
import { Header } from "../../internal/Header/Header";

const Index = ({ children, selectedItem }) => {
  return (
    <Header
      showSidebar
      selectedItem={selectedItem ? selectedItem : labels.daily_casuse}
    >
      {children}
    </Header>
  );
};

export default Index;
