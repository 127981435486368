import React from "react";
import styled from "styled-components";
import { PictureAsPdf } from "@material-ui/icons";
import { getFileName } from "../../../helpers/functions";

export default function Awards() {
  const list = [
    "https://justact-api-uploads.s3.ap-south-1.amazonaws.com/160/1743153492227-5bfb6e47-fefc-4c7e-91bc-fb88ea7af9f4/MCX-SB-2024-01-821020%20-%20-AWARD.pdf",
  ];

  const handleOpenPdf = (pdfUrl) => {
    window.open(pdfUrl, "_blank"); // Opens in a new tab
  };

  return (
    <BodyWrapper>
      <Container>
        <H3>Redacted Awards</H3>
        {/* <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Paragraph> */}
        <Section>
          <Ul>
            {list.map((url) => (
              <Li>
                {/* <Href href={url} rel="noopener" target="_blank">
                  {url}
                </Href> */}
                <PdfContainer onClick={() => handleOpenPdf(url)}>
                  <PdfIconWrapper>
                    <PictureAsPdf className="pdf-icon" />
                  </PdfIconWrapper>

                  <FileName>{getFileName(url) || "Attachment.pdf"}</FileName>

                  <Ribbon />
                </PdfContainer>
              </Li>
            ))}
          </Ul>
        </Section>
      </Container>
    </BodyWrapper>
  );
}

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: left;
  justify-content: left;
  font-size: 14px;
  padding: 1%;
  margin-top: 20px;
  width: 100%;
  @media (max-width: 640px) {
    flex-direction: column;
    padding: 0 15px;
  }
`;

const H3 = styled.div`
  font-weight: 700;
  line-height: 1.2;
  font-size: 23px;
`;

// const Paragraph = styled.p`
//   font-size: 1rem;
//   line-height: 1.5rem;
//   font-family: CWC, system-ui, sans-serif;
//   color: rgb(81 98 111 / 1);
// `;

const Section = styled.section`
  padding-bottom: 1rem;
`;

const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Li = styled.li`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const Container = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
  gap: 1rem;
  text-align: left;
  @media (min-width: 1024px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`;

// const Href = styled.a`
//   font-size: 15px;
//   line-height: 1.5rem;
//   font-weight: 400;
//   font-family: ${theme.fonts.primaryFontSemiBold};
//   color: #00838c;
//   text-decoration-line: underline;
//   color: rgb(81 98 111)
// }
// `;

const PdfContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 8px;
  position: relative;
  cursor: pointer;
  background-color: #f5f5f5;
  transition: background 0.3s ease-in-out;

  &:hover {
    background-color: #e0e0e0;
  }

  @media (max-width: 768px) {
    width: 120px;
  }

  @media (max-width: 480px) {
    width: 100px;
    padding: 6px;
  }
`;

const PdfIconWrapper = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ddd;

  .pdf-icon {
    font-size: 40px;
    color: red;
  }

  @media (max-width: 480px) {
    height: 60px;

    .pdf-icon {
      font-size: 30px;
    }
  }
`;

const FileName = styled.p`
  margin-top: 8px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const Ribbon = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background-color: red;
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
`;
