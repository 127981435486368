import API from "../api/manager";
import config from "../api/config";

const createParty = (data) => {
  return API({
    method: "POST",
    url: config.urls.party.create,
    data,
  });
};

const createPartyDraft = (data) => {
  return API({
    method: "POST",
    url: config.urls.party.draft,
    data,
  });
};

const updatePartyDraft = (data, id) => {
  return API({
    method: "PUT",
    url: config.urls.party.draft + `/${id}`,
    data,
  });
};

const submitPartyDraft = (data, id) => {
  return API({
    method: "PUT",
    url: config.urls.party.create + `/${id}`,
    data,
  });
};

const getPartyOrDraft = (id) => {
  return API({
    method: "GET",
    url: config.urls.party.party + `/${id}`,
  });
};

const getParties = (query) => {
  return API({
    method: "GET",
    url: config.urls.party.party + query,
  });
};

const agentCreate = (id, data) => {
  return API({
    method: "POST",
    url: config.urls.party.party + `/${id}/agents`,
    data,
  });
};

const agentDelete = (id, partyId) => {
  return API({
    method: "DELETE",
    url: config.urls.party.party + `/${partyId}/agents/${id}`,
  });
};

const partyStats = () => {
  return API({
    method: "GET",
    url: config.urls.party.stats,
  });
};

const partyCaseStats = (id) => {
  return API({
    method: "GET",
    url: config.urls.case.stats + `?partyId=${id}`,
  });
};

const getCaseParties = (query) => {
  return API({
    method: "GET",
    url: config.urls.party.party + query,
  });
};

const getDocumentsReport = (query) => {
  return API({
    method: "GET",
    url: config.urls.case.getDocumentsReport + query,
  });
};

export default {
  createParty,
  createPartyDraft,
  getPartyOrDraft,
  updatePartyDraft,
  submitPartyDraft,
  getParties,
  agentCreate,
  agentDelete,
  partyStats,
  partyCaseStats,
  getCaseParties,
  getDocumentsReport,
};
