import React, { useState } from "react";
import {
  CustomInputField,
  CustomDatePicker,
  CustomMobileInput,
  StyledMenuItem,
} from "../FormInputs";
import theme from "../../../assets/theme";
import {
  FormLabel,
  IconButton,
  InputAdornment,
  TextareaAutosize,
} from "@material-ui/core";
import CustomSelect from "../CustomSelect/CustomSelect";
import COLORS from "../../../assets/Colors";
import styled, { css } from "styled-components";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { get } from "lodash";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { CustomFileUpload } from "../FormInputs";
import { getErrorMessage, getFileName } from "../../../helpers/functions";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import AWSService from "../../../services/AWSService";

const filter = createFilterOptions();

export default function FormField({
  touched,
  errors,
  input,
  values,
  handleChange,
  handleBlur,
  menuItems = [],
  setFieldValue,
  setFieldError,
  white = false,
  multiline,
  disabled = false,
  showId = false,
  row = 4,
  saveOnBlur = false,
  maximumLength = 13,
  selectedIndex = "label",
  alias,
  showAllInitial = false,
  currentTab,
  textTransform,
  selectedItemChanged = () => {},
}) {
  const {
    name,
    label,
    type,
    placeholder,
    required,
    autoFocus,
    index,
    uploadName,
  } = input;
  const [searchInput, setSearchInput] = useState();
  const [visibility, setVisibility] = useState(false);
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const createNewOption = (options, params) => {
    let filtered = filter(options, params);
    if (params.inputValue !== "") {
      let temp = {};
      temp["inputValue"] = params.inputValue;
      temp[selectedIndex] = `Add "${params.inputValue}"`;
      filtered = [temp, ...filtered];
    }
    return filtered;
  };

  const calRenderOption = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option[selectedIndex];
  };

  const autoHandleChange = (event, newValue) => {
    if (typeof newValue === "string") {
      setFieldValue(`requestedMediators[${index}].${name}`, newValue.name);
      if (values?.mediatorDocuments?.length > 6) {
      } else {
        setFieldValue(`requestedMediators[${index}].documents`, "error");
      }
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setFieldValue(
        `requestedMediators[${index}].${name}`,
        newValue.inputValue,
      );
      if (values?.mediatorDocuments?.length > 6) {
      } else {
        setFieldValue(`requestedMediators[${index}].documents`, "error");
      }
    } else {
      if (newValue === null) {
        setFieldValue(`requestedMediators[${index}].${name}`, "");
        setFieldValue(`requestedMediators[${index}].mediatorEmail`, "");
        setFieldValue(`requestedMediators[${index}].mediatorMobile`, "");
        setFieldValue(`requestedMediators[${index}].documents`, "");
      } else {
        setFieldValue(`requestedMediators[${index}].${name}`, newValue.name);
        setFieldValue(
          `requestedMediators[${index}].mediatorEmail`,
          newValue.email,
        );
        setFieldValue(
          `requestedMediators[${index}].mediatorMobile`,
          newValue.mobile.substring(3),
        );
        setFieldValue(`requestedMediators[${index}].documents`, "");
      }
    }
  };

  const createNewFilterOptions = (options, params) => {
    const filtered = filter(options, params);

    // Suggest the creation of a new value
    if (params.inputValue !== "") {
      filtered.push({
        inputValue: params.inputValue,
        name: `Add "${params.inputValue}"`,
      });
    }

    return filtered;
  };

  const callAutoRenderOptions = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.name;
  };

  async function s3Upload(file, field, setFieldValue, setFieldError) {
    if (file) {
      try {
        setLoader({ state: true, message: `Uploading file: ${file.name} ...` });
        const payload = {
          key: file.name,
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const res = await AWSService.uploadToS3(s3Response.url, file);
          setFieldValue(field, res);
          enqueueSnackbar("Successfully Uploaded", { variant: "success" });
        }
      } catch (error) {
        const message = getErrorMessage(error);
        setFieldError(field, "Could not upload this file");
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Tab" || event.key === "ArrowDown") {
      setFieldValue(`requestedMediators[${index}].${name}`, event.target.value);
      setFieldValue(`requestedMediators[${index}].mediatorEmail`, "");
      setFieldValue(`requestedMediators[${index}].mediatorMobile`, "");
      setFieldValue(`requestedMediators[${index}].documents`, "error");
      setOpen(false);
    }
  };

  return (
    <div className="form-fields" style={{ width: "100%", paddingTop: 5 }}>
      <FormLabel
        error={errors[name] && touched[name]}
        style={{
          fontFamily: theme.fonts.primaryFontSemiBold,
          fontSize: 12,
          color: COLORS.INPUT_LABEL,
        }}
      >
        {label}
        {required && <span className="required-star"> *</span>}
      </FormLabel>
      {type === "autoselect" ? (
        <Autocomplete
          freeSolo
          disableClearable
          options={showAllInitial || searchInput?.trim() ? menuItems : []}
          name={name}
          value={get(values, name)}
          onChange={(_, v) => {
            v[selectedIndex] = v["inputValue"]
              ? v["inputValue"]
              : v[selectedIndex];
            selectedItemChanged(v);
          }}
          onKeyDown={(e) => {
            if (e.key === "Tab" && saveOnBlur) {
              const v = { [selectedIndex]: e.target.value };
              selectedItemChanged(v);
            }
          }}
          clearOnBlur
          filterOptions={(options, params) => createNewOption(options, params)}
          getOptionLabel={(option) => calRenderOption(option)}
          renderOption={(option) => (
            <React.Fragment>
              {option[alias] ? (
                `${option[selectedIndex]} (${option[alias]})`
              ) : (
                <strong>{option[selectedIndex]}</strong>
              )}
            </React.Fragment>
          )}
          onBlur={handleBlur}
          id={name}
          style={{ marginTop: 6 }}
          disabled={disabled}
          renderInput={(params) => (
            <CustomInputField
              {...params}
              error={errors[name] && touched[name]}
              helperText={touched[name] && errors[name]}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              onBlur={handleBlur}
              className={`input-no-padding ${white ? "input-white" : ""}`}
              variant="outlined"
              InputProps={{ ...params.InputProps, type: "search" }}
              placeholder={placeholder}
            />
          )}
        />
      ) : type === "autoSelect" ? (
        <Autocomplete
          value={values[name]}
          onChange={(event, newValue) => autoHandleChange(event, newValue)}
          filterOptions={(options, params) =>
            createNewFilterOptions(options, params)
          }
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={menuItems}
          getOptionLabel={(option) => callAutoRenderOptions(option)}
          ListboxComponent={(props) => (
            <ul style={{ border: "1px solid #e1e3ee" }} {...props}>
              {props?.children.map((element) => (
                <StyledMenuItem {...element.props}>
                  {element.props.children}
                </StyledMenuItem>
              ))}
            </ul>
          )}
          style={{ marginTop: 6, marginBottom: 10 }}
          renderInput={(params) => (
            <CustomInputField
              {...params}
              error={errors[name] && touched[name]}
              helperText={touched[name] && errors[name]}
              variant="outlined"
              onKeyDown={handleKeyDown}
            />
          )}
        />
      ) : type === "fileUpload" ? (
        <CustomFileUpload
          error={errors[name] && touched[name] ? errors[name] : false}
          setFile={(file) =>
            s3Upload(
              file,
              `${uploadName}[${index}].${name}`,
              setFieldValue,
              setFieldError,
            )
          }
          style={{
            marginTop: 10,
            width: "100%",
          }}
          accept=".pdf"
          file={{
            name: getFileName(values[name]),
          }}
          fileUrl={values[name]}
        />
      ) : type === "select" ? (
        <CustomSelect
          white={white}
          id={name}
          menuItemValues={menuItems}
          name={name}
          value={values[name]}
          onChange={handleChange}
          error={errors[name] && touched[name]}
          helperText={touched[name] && errors[name]}
          onBlur={handleBlur}
          style={{ marginTop: 6 }}
          disabled={disabled}
          showId={showId}
        />
      ) : type === "date" ? (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <CustomDatePicker
            className={white ? "input-white" : ""}
            disableFuture
            inputVariant="outlined"
            openTo="year"
            disabled={disabled}
            error={errors[name] && touched[name]}
            helperText={touched[name] && errors[name]}
            maxDate={
              new Date(
                new Date().getFullYear() - 18,
                new Date().getMonth(),
                new Date().getDate(),
              )
            }
            format="dd MMMM yyyy"
            views={["year", "month", "date"]}
            value={values[name]}
            onChange={(e) => setFieldValue(name, e)}
            style={{ marginTop: 6 }}
            inputProps={{
              placeholder: placeholder,
            }}
          />
        </MuiPickersUtilsProvider>
      ) : name === "mobile" ? (
        currentTab === "mobile" ? (
          <div className="mobile-input">
            <CustomMobileInput
              disabled={disabled}
              className={white ? "input-white" : ""}
              defaultCountry={"in"}
              onlyCountries={["in"]}
              onChange={(e) => setFieldValue(name, e)}
              onBlur={handleBlur}
              autoFormat={false}
              style={{ marginTop: 6 }}
              value={values[name]}
              error={errors[name] && touched[name]}
              helperText={touched[name] && errors[name]}
              id={name}
              placeholder={placeholder}
              name={name}
              variant="outlined"
              inputProps={{
                maxLength: maximumLength,
              }}
            />
          </div>
        ) : (
          <div className="mobile-input">
            <CustomMobileInput
              disabled={disabled}
              className={white ? "input-white" : ""}
              defaultCountry={"in"}
              onChange={(e) => setFieldValue(name, e)}
              onBlur={handleBlur}
              autoFormat={false}
              style={{ marginTop: 6 }}
              value={values[name]}
              error={errors[name] && touched[name]}
              helperText={touched[name] && errors[name]}
              id={name}
              placeholder={placeholder}
              name={name}
              variant="outlined"
              inputProps={{
                maxLength: maximumLength,
              }}
            />
          </div>
        )
      ) : type === "textarea" ? (
        <Relative>
          <CustomTextArea
            className={
              white && multiline
                ? "input-white input-height-auto"
                : white
                ? "input-white"
                : multiline
                ? "input-height-auto"
                : ""
            }
            disabled={disabled}
            rowsMin={5}
            rowsMax={5}
            autoComplete={"new-password"}
            type={type}
            value={values[name]}
            error={errors[name] && touched[name]}
            helperText={touched[name] && errors[name]}
            id={name}
            maxLength={1000}
            placeholder={placeholder}
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            style={{ marginTop: 6 }}
          />
          <MaxLength>
            {1000 - values[name].length} characters remaining
          </MaxLength>
          <HelperText>{touched[name] && errors[name]}</HelperText>
        </Relative>
      ) : (
        <CustomInputField
          className={
            white && multiline
              ? "input-white input-height-auto"
              : white
              ? "input-white"
              : multiline
              ? "input-height-auto"
              : ""
          }
          multiline={multiline}
          row={4}
          autoComplete={"new-password"}
          autoFocus={name === "fullname" || autoFocus}
          type={type === "password" ? (visibility ? "text" : type) : type}
          value={values[name]}
          error={errors[name] && touched[name]}
          helperText={touched[name] && errors[name]}
          id={name}
          placeholder={placeholder}
          name={name}
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          style={{ marginTop: 6, marginBottom: 10 }}
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <>
                {type === "password" ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setVisibility(!visibility)}
                      edge="end"
                    >
                      {visibility ? (
                        <Visibility style={{ fontSize: 18 }} />
                      ) : (
                        <VisibilityOff style={{ fontSize: 18 }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ) : null}
              </>
            ),
          }}
          inputProps={{
            maxLength:
              label === "NEW AGENT PAN" ||
              label === "PAN" ||
              name === "respondentPanNumber"
                ? 10
                : label === "CIN"
                ? 21
                : null,
            style: {
              textTransform: textTransform
                ? textTransform
                : input?.textTransform,
            },
          }}
          min={0}
        />
      )}
    </div>
  );
}

const Relative = styled.div`
  position: relative;
`;

const CustomTextArea = styled(TextareaAutosize)`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  font-size: 16px;
  color: ${COLORS.COLOR_DARK} !important;
  font-family: ${theme.fonts.primaryFontSemiBold};
  /* min-height: 89px; */
  border-radius: 6px;
  border: solid 0.5px #e1e3ee;
  background-color: #ffffff;
  outline: none;
  padding: 15px;
  :hover {
    border: solid 0.5px black;
  }
  ${({ error }) =>
    error &&
    css`
      border: solid 0.5px #f44336;
      :hover {
        border: solid 0.5px #f44336;
      }
    `}
`;

const MaxLength = styled.div`
  font-family: openSans-SemiBold, sans-serif;
  font-size: 10px;
  color: #acb1c2;
  text-align: right;
`;

const HelperText = styled.div`
  position: absolute;
  left: 3px;
  bottom: 2px;
  position: absolute;
  font-size: 10px;
  font-family: openSans-SemiBold, sans-serif;
  color: #f44336;
`;
