import React, { useState } from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import useLoader from "../../../hooks/useLoader";
import {
  makeStyles,
  Grid,
  Paper,
  Typography,
  Modal,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import {
  PrimaryOutlinedCTAButton,
  PrimaryCTAButton,
} from "../../common/Buttons";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { Formik } from "formik";
import * as XLSX from "xlsx";
import { CustomFileUpload } from "../../common/FormInputs";
import { useSnackbar } from "notistack";
import CaseService from "../../../services/CaseService";

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: COLORS.PRIMARY_WHITE,
    boxShadow: "none",
    width: "77px",
    height: "30px",
    color: COLORS.BTN_GREEN,
    textTransform: "Capitalize",
    border: "solid 1px #00838c",
    fontFamily: theme.fonts.primaryFontBold,
    fontSize: 14,
  },
  formLabel: {
    fontFamily: theme.fonts.primaryFontSemiBold,
    color: COLORS.COLOR_DARK,
  },
  table: {
    backgroundColor: "transparent",
    border: "1px solid #e1e3ee",
    borderRadius: 7,
    "& .MuiTableCell-root": {
      borderBottom: 0,
    },
  },
  tableHead: {
    fontFamily: "openSans-SemiBold, sans-serif",
    color: COLORS.INPUT_LABEL,
    fontSize: 12,
  },
  tableBody: {
    fontFamily: "openSans-SemiBold, sans-serif",
    color: COLORS.COLOR_DARK,
    fontSize: 12,
  },
}));

const BulkDownload = ({ bulkDownload, setBulkDownload }) => {
  const classes = useStyles();
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [modal, setModal] = useState(false);
  const [fileData, setFileData] = useState();

  const viewing_items = [
    {
      label: "Claimant documents",
      name: "claimant",
      value: "claimant",
    },
    {
      label: "Respondent documents",
      name: "respondent",
      value: "respondent",
    },
    {
      label: "Neutral documents",
      name: "other",
      value: "other",
    },
  ];

  async function s3Upload(file, filedName, setFieldValue, fileName) {
    if (file) {
      try {
        setLoader({ state: true, message: `Uploading file ${file.name}` });
        // const errorMessage = "Invalid File Type";
        // if (!fileTypes.includes(file?.type)) throw errorMessage;
        const response = await readExcelFile(file, fileName, setFieldValue);
        setFieldValue(filedName, response);
        if (response?.length) {
          setFieldValue(fileName, file.name);
        }
      } catch (err) {
        enqueueSnackbar(err, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  /**
   * @description Read xlsx file
   * @param {*} file
   * @returns
   */

  const readExcelFile = async (file) => {
    const arrayBuffer = await file.arrayBuffer(); // Convert file to ArrayBuffer
    const workbook = XLSX.read(arrayBuffer, { type: "array" }); // Read the workbook
    const sheetName = workbook.SheetNames[0]; // Get the first sheet
    const sheet = workbook.Sheets[sheetName]; // Access the first sheet
    const data = XLSX.utils.sheet_to_json(sheet, { header: 1 }); // Convert to 2D array

    const headerRow = data[0]; // First row is the header
    const possibleHeaders = [
      "CONTRACT NUMBER",
      "Contract No",
      "Contract Number",
      "contract no",
    ];
    const contractNumberIndex = headerRow.findIndex((header) =>
      possibleHeaders.includes(header.trim()),
    );

    if (contractNumberIndex === -1) {
      enqueueSnackbar("Contract number column not found", {
        variant: "error",
      });
    }

    // // Extract values under "CONTRACT NUMBER" column
    const contractNumbers = data
      .slice(1)
      .map((row) => row[contractNumberIndex])
      .filter(Boolean);

    return contractNumbers;
  };

  /**
   * @description optimized code with concurrency control
   */

  const MAX_FILES = 3500; // Set a limit to prevent excessive downloads
  const BATCH_SIZE = 10; // Download in batches to prevent overload

  const downloadFilesAsZip = async (data, fieldName) => {
    console.log(data, fieldName);
    const zip = new JSZip();
    const totalFiles = Object.values(data).flat().length;

    if (totalFiles > MAX_FILES) {
      enqueueSnackbar(
        `You have selected ${totalFiles} files. Please download within the limit of ${MAX_FILES} files.`,
        {
          variant: "info",
        },
      );
      return;
    }

    let completedFiles = 0;
    const fileTracker = new Map(); // Track filenames

    const downloadFile = async (url, folder) => {
      try {
        setLoader({
          state: true,
          message: `Downloaded: ${completedFiles}/${totalFiles}`,
        });
        const response = await fetch(url);
        if (!response.ok) throw new Error(`Failed to fetch: ${url}`);

        const blob = await response.blob();
        let fileName = decodeURIComponent(url.split("/").pop());

        // Handle duplicate filenames
        if (fileTracker.has(fileName)) {
          let count = fileTracker.get(fileName) + 1;
          fileTracker.set(fileName, count);
          const fileExt = fileName.includes(".")
            ? fileName.split(".").pop()
            : "";
          const baseName = fileName.replace(`.${fileExt}`, "");
          fileName = `${baseName}_${count}.${fileExt}`;
        } else {
          fileTracker.set(fileName, 1);
        }

        folder.file(fileName, blob);
        completedFiles++;
      } catch (error) {
        enqueueSnackbar(`"Download error:", ${error?.message}`, {
          variant: "error",
        });
      }
    };

    const downloadBatch = async (batch) => {
      await Promise.all(
        batch.map(({ url, folder }) => downloadFile(url, folder)),
      );
    };

    const allFiles = [];
    for (const [folderName, urls] of Object.entries(data)) {
      const folder = zip.folder(folderName);
      urls.forEach((url) => allFiles.push({ url, folder }));
    }

    for (let i = 0; i < allFiles.length; i += BATCH_SIZE) {
      const batch = allFiles.slice(i, i + BATCH_SIZE);
      await downloadBatch(batch);
    }

    setLoader({ state: true, message: "Generating ZIP file..." });
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${fieldName}.zip`);
      setLoader({ state: false });
      console.log("Download completed!");
    });
  };

  /**
   * @description from submit
   */

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true, message: "" });
      const stringParams = `?contractNos=${values?.contractNumber.join(
        ",",
      )}&ownerRole=${values?.ownerType}`;
      const response = await CaseService.getBulkDownloadDocumentDet(
        stringParams,
      );
      const docxFiles = Object.fromEntries(
        Object.entries(response)
          .map(([key, urls]) => [
            key,
            urls.filter((url) => {
              const isDocx = url.endsWith(".docx");
              const isPdf = url.endsWith(".pdf");
              const isImage = /\.(jpg|jpeg|png|gif|png|tif|tiff)$/i.test(url);

              return (
                (values.documentType.includes("docx") && isDocx) ||
                (values.documentType.includes("pdf") && isPdf) ||
                (values.documentType.includes("images") && isImage) ||
                values.documentType.includes("all")
              );
            }),
          ])
          .filter(([_, urls]) => urls.length > 0), // Remove empty arrays
      );
      setFileData(docxFiles);
      if (response) {
        enqueueSnackbar("Successfully Feched all the files", {
          variant: "success",
        });
      }
      if (response) {
        enqueueSnackbar("Successfully Feched all the files", {
          variant: "success",
        });
      }
    } catch (err) {
      enqueueSnackbar(err?.message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setModal(true);
    }
  }

  return (
    <Modal
      open={bulkDownload}
      onClose={() => setBulkDownload(false)}
      disableRestoreFocus={true}
    >
      <DrawerContainer>
        <Container>
          <Paper elevation={3} style={{ padding: "16px" }}>
            <HeadingContainer>
              <Heading>Bulk Download</Heading>
            </HeadingContainer>

            <Formik
              initialValues={{
                contractNumber: "",
                fileName: "",
                ownerType: "other",
              }}
              enableReinitialize
              validateOnBlur
              validateOnChange
              onSubmit={onFormSubmit}
            >
              {({
                values,
                handleChange,
                errors,
                handleSubmit,
                touched,
                setFieldValue,
                setFieldError,
                handleBlur,
                isValid,
              }) => (
                <>
                  {modal ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        spacing={2}
                        style={{
                          marginTop: "16px",
                          padding: 16,
                        }}
                      >
                        <Description>
                          {`Are you sure you want to download all the contract number related files?`}
                        </Description>
                      </Grid>
                      <Flex>
                        <HalfWidth>
                          <PrimaryOutlinedCTAButton
                            style={{ padding: "10px 10px" }}
                            onClick={() => setModal(false)}
                          >
                            {"Back"}
                          </PrimaryOutlinedCTAButton>
                        </HalfWidth>

                        <HalfWidth>
                          <PrimaryCTAButton
                            style={{ padding: "10px 10px" }}
                            onClick={() =>
                              downloadFilesAsZip(fileData, values.fileName)
                            }
                          >
                            {"Download"}
                          </PrimaryCTAButton>
                        </HalfWidth>
                      </Flex>
                    </>
                  ) : (
                    <>
                      <Grid
                        item
                        xs={12}
                        spacing={2}
                        style={{ marginTop: "16px", padding: 16 }}
                      >
                        <RadioGroup
                          className={classes.radioGroup}
                          style={{
                            flexDirection: "column",
                            marginLeft: "10px",
                          }}
                          name={"ownerType"}
                          value={values.ownerType}
                          onChange={handleChange}
                        >
                          {viewing_items.map((radio, key) => (
                            <FormControlLabel
                              {...{ key }}
                              classes={{ label: classes.formLabel }}
                              value={radio.value}
                              // checked={values.ownerType === radio.name}
                              control={
                                <Radio
                                  size="medium"
                                  style={{ color: "#00838c" }}
                                />
                              }
                              label={radio.label}
                            />
                          ))}
                        </RadioGroup>
                      </Grid>
                      <FormContainer>
                        <Row
                          style={{
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <StyledLabel>Upload xlsx file </StyledLabel>
                        </Row>
                        <div style={{ marginBottom: 13 }}>
                          <CustomFileUpload
                            setFile={(file) =>
                              s3Upload(
                                file,
                                `contractNumber`,
                                setFieldValue,
                                `fileName`,
                              )
                            }
                            style={{
                              marginTop: 10,
                              width: "100%",
                            }}
                            accept=".xlsx"
                            file={{ name: values.fileName }}
                          />
                        </div>
                        <Flex>
                          <HalfWidth>
                            <PrimaryOutlinedCTAButton
                              style={{ padding: "10px 10px" }}
                              onClick={() => setBulkDownload(false)}
                            >
                              {"Cancel"}
                            </PrimaryOutlinedCTAButton>
                          </HalfWidth>

                          <HalfWidth>
                            <PrimaryCTAButton
                              style={{ padding: "10px 10px" }}
                              disabled={!values.contractNumber.length}
                              onClick={handleSubmit}
                            >
                              {"Submit"}
                            </PrimaryCTAButton>
                          </HalfWidth>
                        </Flex>
                      </FormContainer>
                    </>
                  )}
                </>
              )}
            </Formik>
          </Paper>
        </Container>
      </DrawerContainer>
    </Modal>
  );
};

export default BulkDownload;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const FormContainer = styled.div`
  padding-top: 5px;
  padding-left: 28px;
  padding-right: 54px;
  padding-bottom: 6px;
  & form {
    padding-top: 10px;
  }
`;

const StyledLabel = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 12px;
  line-height: 1.83;
  text-transform: capitalize;
  color: ${COLORS.COLOR_DARK};
`;

const Heading = styled(Typography)(() => ({
  backgroundColor: "#A5BFCC",
  color: "#fff",
  padding: "8px",
  fontFamily: theme.fonts.primaryFontBold,
  borderRadius: "5px",
}));

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 516px;
  }
`;

const HeadingContainer = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 10px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media ${theme?.breakpoints?.sm_up} {
    width: auto;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 47px;
  padding-left: 24px;
  padding-right: 24px;
`;

const HalfWidth = styled.div`
  width: 48%;
`;

const Description = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  line-height: 1.71;
  color: ${COLORS.COLOR_DARK};
  text-align: center;
  width: 100%;
  min-width: 100%;
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 14px;
    min-width: 440px;
  }
`;
