import React from "react";
import { Router } from "@reach/router";
import SignupPage from "./components/pages/SignupPage";
import LoginPage from "./components/pages/LoginPage";
import OtpPage from "./components/pages/OtpPage";
import AccountVerificationPage from "./components/pages/AccoutVerificationPage";
import Createprofile from "./components/pages/CreateProfile";
import Welcome from "./components/pages/Welcome";
import ForgotPassword from "./components/pages/ForgotPassword";
import ResetPassword from "./components/pages/ResetPassword";
import ChangePassword from "./components/pages/ChangePassword";
import Parties from "./components/pages/Parties";
import Cases from "./components/pages/Cases";
import MyProfile from "./components/pages/MyProfile";
import PaymentsPage from "./components/pages/PaymentsPage";
import HelpPage from "./components/pages/HelpPage";
import MyParties from "./components/internal/MyParties/";
import MyCases from "./components/pages/MyCases/";
import CreatePartyPage from "./components/pages/CreatePartyPage/";
import PartyDetailsPage from "./components/pages/PartyDetailsPage";
import Dashboard from "./components/pages/Dashboard";
import MyDashboard from "./components/internal/MyDashboard/";
import DashboardScreens from "./components/pages/DashboardScreens";
import AccountVerificationParent from "./components/pages/AccoutVerificationPage/AccountVerificationParent";
import NotVerifiedPage from "./components/pages/NotVerifiedPage";
import LoginPath from "./components/pages/LoginPage/LoginPath";
import LoginOTP from "./components/pages/LoginOTP";
import CreateCase from "./components/internal/CreateCase";
import CreateCase2 from "./components/internal/CreateCase2";
import CreateCase3 from "./components/pages/CreateCase3";
import CaseRequests from "./components/internal/CaseRequests";
import CaseCart from "./components/internal/CaseCart";
import CaseInvites from "./components/internal/CaseInvites";
import Help from "./components/internal/Help";
import ProfileParent from "./components/pages/MyProfile/components/Parent";
import CasesBulkCreate from "./components/pages/CasesBulkCreate";
import DemoPage from "./components/pages/DemoPage";
import RatePlatform from "./components/pages/RatePlatform";
import NegotiationPages from "./components/pages/NegotiationPages";
import AwaitingRespondantPage from "./components/pages/NegotiationPages/AwaitingRespondantPage";
import LandingPage from "./components/pages/LandingPage";
import NotFoundPage from "./components/pages/NotFoundPage";
import ProfilePage from "./components/pages/ProfilePage/ProfilePage";
import ProfileScreen from "./components/pages/ProfileScreen";
import Training from "./components/pages/Training/Training";
import Resources from "./components/pages/Resources/Resources";
import Blog from "./components/pages/Resources/index";
import Webinar from "./components/pages/Resources/Webinar";
import ModelClauses from "./components/pages/Resources/ModelClauses";
import LongTraining from "./components/pages/Training/LongTraining";
import ShortTraining from "./components/pages/Training/ShortTraining";
import WorldofMediation from "./components/pages/Training/WorldOfMediation";
import AboutUs from "./components/pages/AboutUs/AboutUs";
import NegotiationRules from "./components/pages/Rules/NegotiationRules";
import MediationRules from "./components/pages/Rules/MediationRules";
import NegotiationClauseRules from "./components/pages/Rules/NegotiationClauseRules.js";
// import ModelClauseRules from "./components/pages/Rules/ModelClauseRules.js";
import MediationClauseRules from "./components/pages/Rules/MediationClauseRules.js";
import ArbitrationClauseRules from "./components/pages/Rules/ArbitrationClauseRules.js";
import ArbitRules from "./components/pages/Rules/ArbitRules.js";
import Directors from "./components/pages/AboutUs/Directors";
import ManagementTeam from "./components/pages/AboutUs/ManagementTeam";
import OnlineDisputeBlog from "./components/pages/Resources/OnlineDisputeBlog";
import OnlineDisputeBlogTwo from "./components/pages/Resources/OnlineDisputeTwo";
import OnlineDisputeBlogThree from "./components/pages/Resources/OnlineDisputeThree";
import OnlineDisputeBlogFour from "./components/pages/Resources/OnlineDisputeFour";
import OnlineDisputeBlogFive from "./components/pages/Resources/OnlineDisputeBlogFive";
import OnlineDisputeBlogSix from "./components/pages/Resources/OnlineDisputeBlogSix";
import OnlineDisputeBlogSeven from "./components/pages/Resources/OnlineDisputeBlogSeven";
import OnlineDisputeBlogEight from "./components/pages/Resources/OnlineDisputeBlogEight";
import OnlineDisputeBlogNine from "./components/pages/Resources/OnlineDisputeBlogNine";
import OnlineDisputeBlogTen from "./components/pages/Resources/OnlineDisputeBlogTen";
import OnlineDisputeBlogEleven from "./components/pages/Resources/OnlineDisputeBlogEleven";
import OnlineDisputeBlogTwelve from "./components/pages/Resources/OnlineDisputeBlogTwelve";
import OnlineDisputeBlogThirteen from "./components/pages/Resources/OnlineDisputeBlogThirteen";
import OnlineDisputeBlogFourteen from "./components/pages/Resources/OnlineDisputeFourteen";
import OnlineDisputeBlogFifteen from "./components/pages/Resources/OnlineDisputeFifteen";
import OnlineDisputeBlogSixteen from "./components/pages/Resources/OnlineDisputeBlogSixteen";
import OnlineDisputeBlogSeventeen from "./components/pages/Resources/OnlineDisputeBlogSeventeen";
import OnlineDisputeBlogEighteen from "./components/pages/Resources/OnlineDisputeBlogEighteen";
import OnlineDisputeBlogNineteen from "./components/pages/Resources/OnlineDisputeBlogNineteen";
import OnlineDisputeBlogTwenty from "./components/pages/Resources/OnlineDisputeBlogTwenty.js";
import TermsAndCondition from "./components/pages/TermsAndConditions/TermsAndCondition";
import PrivacyPolicy from "./components/pages/PrivacyPolicy/PrivacyPolicy";
import PaymentHistoryPage from "./components/internal/PaymentHistoryPage";
import ProfomaCaseListing from "./components/internal/ProfomaCaseListing";
import NotificationPage from "./components/pages/NotificationPage";
import Notification from "./components/internal/Notification/MobileNotification";
import CreateCasePartyPage from "./components/pages/CreateCasePartPage";
import GuestUserMeeting from "./components/pages/JoinMeeting.js";
import GuestUserEndMeeting from "./components/pages/EndMeeting.js";
import EndGenericMeeting from "./components/pages/EndGenericMeeting";
import TimeSlideTranscription from "./components/pages/TimeSlideTranscription/index";
import LiveTranscription from "./components/internal/TimeSlideTranscript/LiveTranscription";
import CrossExamination from "./components/internal/CrossExamination/index";
import CrossExaminationTranscript from "./components/internal/CrossExamination/CrossExaminationTranscripts";
import DailyCausePage from "./components/pages/DailyCausePage";
// import DailyCause from "./components/internal/DailyCause";
import DailyCause from "./components/calender/DailyCause";
import ModifyCase from "./components/internal/RespondentModifyCase/index.js";
import NoticeGenerationPage from "./components/pages/Notice";
import NoticeGeneration from "./components/internal/NoticeGeneration/index.js";
import NonArbitrableCases from "./components/pages/Resources/NonArbitrableCases.js";
import LimitationOfArbitration from "./components/pages/Resources/LimitationOfArbitration.js";

/* Mis Dashboard */
import MisRoute from "./components/pages/MisRoute";
import MisDashboard from "./components/Mis/index.js";
import ExpertsInArbitration from "./components/pages/Resources/ExpertsInArbitration.js";
import TechniqueForResolution from "./components/pages/Resources/TechniqueForResolution.js";
import CountryOfMediators from "./components/pages/Resources/CountryOfMediators.js";
import CountryOfArbitrators from "./components/pages/Resources/CountryOfArbitrators.js";
import RoleOfMediation from "./components/pages/Resources/RoleOfMediation.js";
import EnterprisesDevelopmentPart1 from "./components/pages/Resources/EnterprisesDevelopmentPart1.js";
import EnterprisesDevelopmentPart2 from "./components/pages/Resources/EnterprisesDevelopmentPart2.js";
import ExpertsAsArbitrator from "./components/pages/Resources/ExpertsAsArbitrators.js";
import MediationInFamily from "./components/pages/Resources/MediationInFamily.js";
import CommercialDisputes from "./components/pages/Resources/CommercialDisputes.js";
import Awards from "./components/pages/Resources/Awards.js";

/* Bulk upload reports */
import ReportsRoute from "./components/pages/ReportsRoute";
import BulkUploadReports from "./components/internal/BulkUploadReports/index.js";
import ManagementTools from "./components/internal/ManagementTools/index.js";

const AppRouter = () => {
  return (
    <Router>
      <NotFoundPage default />
      <NotFoundPage
        errorCode="Error Code : 500"
        errorMessage="The server encountered an internal error or misconfiguration and was unable to complete your request."
        path="/internal-error"
      />
      <NotFoundPage
        errorCode="Error Code : 401"
        errorMessage="You do not have the permission to view the pages"
        path="/un-authorized"
        url="/login"
        buttonLabel="Go to Login"
      />
      <LandingPage path="/" />
      <ProfileScreen path="/neutral/:neutral" />
      <ProfilePage path="/neutral/:neutral/:id" />
      <Training path="/training">
        <NotFoundPage default />
        <LongTraining path="/mediation-training-programme" />
        <ShortTraining path="/corporate-short-programme" />
        <WorldofMediation path="/world-of-mediation" />
      </Training>
      <Resources path="/resources">
        <NotFoundPage default />
        <Blog path="/blogs" />
        <Webinar path="/webinar-videos" />
        <ModelClauses path="/model-clauses" />
        <Awards path="/awards" />
      </Resources>

      <AboutUs path="/about-us" />
      <NegotiationRules path="/negotiation-rules" />
      <ArbitRules path="/arbitration-rules" />
      <MediationRules path="/mediation-rules" />
      {/* <ModelClauseRules path="/modelclause-rules" /> */}
      <NegotiationClauseRules path="/negotiation-model-clause" />
      <MediationClauseRules path="/mediation-model-clause" />
      <ArbitrationClauseRules path="/arbitration-model-clause" />
      <Directors path="/directors" />
      <ManagementTeam path="/management-team" />
      <OnlineDisputeBlog path="/blog/the-basics-of-online-dispute-resolution" />
      <OnlineDisputeBlogTwo path="/blog/online-dispute-resolution-opportunities-and-challenges" />
      <OnlineDisputeBlogThree path="/blog/understanding-arbitration-and-the-arbitration-act" />
      <OnlineDisputeBlogFour path="/blog/the-scope-and-relevance-of-ODR" />
      <OnlineDisputeBlogFive path="/blog/resolving-women-related-disputes" />
      <OnlineDisputeBlogSix path="/blog/ADR-and-its-applications-to-women-related-disputes" />
      <OnlineDisputeBlogSeven path="/blog/evaluating-ADR-for-resolving-women-related-disputes" />
      <OnlineDisputeBlogEight path="/blog/impeding-or-expediting-the-arbitration-process" />
      <OnlineDisputeBlogNine path="/blog/advantages-of-ODR" />
      <OnlineDisputeBlogTen path="/blog/tackling-pending-cases-in-indian-courts" />
      <OnlineDisputeBlogEleven path="/blog/culture-of-mediation-why-has-it-not-picked-up-in-india" />
      <OnlineDisputeBlogTwelve path="/blog/enforcement-of-foreign-arbital-awards" />
      <OnlineDisputeBlogThirteen path="/blog/perkins-eastman-judgement" />
      <OnlineDisputeBlogFourteen path="/blog/non-arbitrable-cases" />
      <OnlineDisputeBlogFifteen path="/blog/time-cost-benefits-of-arbitration-mediation" />
      <OnlineDisputeBlogSixteen path="/blog/limitation-in-arbitration" />
      <OnlineDisputeBlogSeventeen path="/blog/enforcement-of-foreign-arbitral-awards" />
      <OnlineDisputeBlogEighteen path="/blog/role-of-arbitration-in-criminal-cases" />
      <OnlineDisputeBlogNineteen path="/blog/mediation-act-2023" />
      <OnlineDisputeBlogTwenty path="/blog/role-of-arbitration-in-criminal-cases-part-2" />
      <NonArbitrableCases path="/blog/non-arbitrable-cases-two" />
      <LimitationOfArbitration path="/blog/limitation-in-arbitration-two" />
      <ExpertsInArbitration path="/blog/experts-in-arbitration" />
      <TechniqueForResolution path="/blog/technique-for-resolution" />
      <CountryOfMediators path="/blog/country-of-mediators" />
      <CountryOfArbitrators path="/blog/country-of-arbitrators" />
      <RoleOfMediation path="/blog/role-of-mediation" />
      <EnterprisesDevelopmentPart1 path="/blog/enterprises-development-part-one" />
      <EnterprisesDevelopmentPart2 path="/blog/enterprises-development-part-two" />
      <ExpertsAsArbitrator path="/blog/experts-as-arbitrators" />
      <MediationInFamily path="/blog/mediation-in-family" />
      <CommercialDisputes path="/blog/commercial-disputes" />
      <TermsAndCondition path="/terms-and-conditions" />
      <PrivacyPolicy path="/privacy-policy" />
      <SignupPage path="/signup" />
      <SignupPage isGoogleSignUp path="/google-signup" />
      <LoginPath path="/login">
        <NotFoundPage default />
        <LoginPage path="/" />
        <LoginOTP path="/otp" />
      </LoginPath>
      <ResetPassword path="reset-password/:resetToken" />
      <ForgotPassword path="forgot-password" />
      <OtpPage path="/otp" />
      <Createprofile path="/create-profile" />
      <AccountVerificationParent path="/verify-email">
        <AccountVerificationPage path="/" />
        <AccountVerificationPage path="/:email_token" />
      </AccountVerificationParent>
      <NotVerifiedPage path="/not-verifed" />
      {process.env.NODE_ENV === "development" ? (
        <DemoPage path="/demo-components" />
      ) : null}
      <DashboardScreens path="/dashboard">
        <NotFoundPage default />
        <Welcome path="/welcome" />
        <Dashboard path="/">
          <NotFoundPage default />
          <MyDashboard path="/" />
          <CaseRequests path="/requests" />
          <CaseInvites path="/invites" />
        </Dashboard>
        {/* demo page for components */}
        <Cases path="/cases">
          <NotFoundPage default />
          {/* All Cases ("/"), Create Case ("/create") Routes to Go Here (Similar to parties) */}
          <MyCases path="/" />
          <CasesBulkCreate path="/bulk-create" />
          <CreateCase path="/create" />
          <CreateCase path="/create/:caseId" />
          <CreateCase2 path="/create/:caseId/step-2" />
          <CreateCase3 path="/create/:caseId/step-3" />
          <ModifyCase path="/accept/:caseId" />
          <TimeSlideTranscription path="/:id/transcription" />
          <LiveTranscription path="/:id/livetranscription" />
          <CrossExamination path="/:id/cross-examination" />
          <CrossExaminationTranscript path="/:id/cross-examination-transcript" />
          <NegotiationPages path="/:id">
            <NotFoundPage default />
            <AwaitingRespondantPage path="/" />
          </NegotiationPages>
        </Cases>
        <CaseCart path="/cart" />
        <ProfomaCaseListing path="/cart/selected-cases" />
        <Parties path="/parties">
          <NotFoundPage default />
          <MyParties path="/" />
          <PartyDetailsPage path="/:partyId" />
          <CreatePartyPage path="/create" />
          <CreatePartyPage path="/draft/:draftId" />
          <CreateCasePartyPage path="/create/:resolutionKind" />
        </Parties>
        <ProfileParent path="/profile">
          <NotFoundPage default />
          <MyProfile path="/" />
          <ChangePassword path="/change-password" />
        </ProfileParent>
        <PaymentsPage path="/payments">
          <NotFoundPage default />
          {/* Payments Routes to Go Here (Similar to parties) */}
          <PaymentHistoryPage path="/" />
        </PaymentsPage>
        <DailyCausePage path="/daily-cause">
          <DailyCause path="/" />
        </DailyCausePage>
        <MisRoute path="/mis-dashboard">
          <MisDashboard path="/" />
        </MisRoute>
        <NoticeGenerationPage path="/notice">
          <NoticeGeneration path="/" />
        </NoticeGenerationPage>
        <NotificationPage path="/notification">
          <NotFoundPage default />
          <Notification path="/" />
        </NotificationPage>
        <ReportsRoute path="/document-reports">
          <BulkUploadReports path="/" />
        </ReportsRoute>
        <DailyCausePage
          path="/management-tools"
          selectedItem={"management_tools"}
        >
          <ManagementTools path="/" />
        </DailyCausePage>
        <HelpPage path="/help">
          <NotFoundPage default />
          {/* Payments Routes to Go Here (Similar to parties) */}
          <Help path="/" />
        </HelpPage>
        <RatePlatform path="/rate-platform/:id" />
      </DashboardScreens>
      <GuestUserMeeting path="/joinMeeting/:code" />
      <GuestUserEndMeeting path="/endMeeting/:caseId" />
      <EndGenericMeeting path="/endMeetingCM/:id" />
    </Router>
  );
};

export default AppRouter;
